import request from '@/utils/request'


// 查询头部数据
export function getSensorData(query) {
  return request({
    url: '/deviceReading/deviceReading/getChartStatBySerialNumber',
    method: 'post',
    data: query
  })
}

//获取 Diagram 图表数据

export function getDiagramData(query) {
    return request({
      url: '/deviceReading/deviceReading/getDiagramData',
      method: 'post',
      data: query
    })
  }

  // 获取 iCGM 图表数据
  export function getiCGMData(query) {
    return request({
      url: '/deviceReading/deviceReading/getiCGMData',
      method: 'post',
      data: query
    })
  }

